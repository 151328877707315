export const partners = [
  {
    id: 1,
    image: require("../assets/images/logos-5.png"),
    link: "https://fdrs-ltd.com",
  },
  {
    id: 2,
    image: require("../assets/images/logos-6.png"),
    link: "https://container-xchange.com/",
  },
  {
    id: 3,
    image: require("../assets/images/logos-7.png"),
    link: "https://zybotech.com",
  },
  {
    id: 4,
    image: require("../assets/images/logos-8.png"),
    link: "https://inspexion.com/",
  },
  // {
  //   id: 6,
  //   image: require("../assets/images/RatesLand2.jpg"),
  //   link: "https://ratesland.com",
  // },
  {
    id: 7,
    image: require("../assets/images/shipnet.png"),
    link: "https://shipnext.com/",
  },
  {
    id: 8,
    image: require("../assets/images/CargoWise2.png"),
    link: "https://www.cargowise.com",
  },
  {
    id: 9,
    image: require("../assets/images/hexcore.png"),
    link: "http://www.hexcore.ai/",
  },
  {
    id: 10,
    image: require("../assets/images/kolyns.png"),
  },
];

export const membersOf = [
  {
    id: 3,
    image: require("../assets/images/home/fiata-wbg.png"),
    link: "https://fiata.org/",
  },
  {
    id: 1,
    image: require("../assets/images/home/itc-wbg.png"),
    link: "https://www.ciffa.com",
  },
  {
    id: 2,
    image: require("../assets/images/home/ciffa-wbg.png"),
    link: "https://thetradecouncil.com",
  },
];

export const testimonials = [
  {
    comment: `Dear Prolog Team, 
              Although we joined as Prolog members quite late, the coordination was still exceptional, thanks to Chaitali’s swift efforts. The event was well-coordinated, and I could sense the wholehearted effort from both Ganga and the team to make it a success. 
              It was a pleasure attending the conference, and I truly enjoyed the opportunity to reconnect with old friends and make new connections. 
              I look forward to seeing all of you, and many more, in the days to come. 
              Let’s continue to do happy business together under Prolog! 
              GO PRO 👍🏻👍🏻 `,
    author: "Darshan Radhakrishnan",
    designation: "Commercial Director - Global Haul Africa",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/Global%20Haul%20Logo.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Flag_of_Zimbabwe.svg/383px-Flag_of_Zimbabwe.svg.png",
  },
  {
    comment:
      "Thanks Prolog team.....very well organised from day 1 to end......Happy to be Prolog......Go Pro ....👍👍",
    author: "Sudharshan",
    designation: "Vice-President - Shiftco",
    logo: "https://nucleusprolognet.s3.amazonaws.com/gopi%40shiftcoship.com/logo-01.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/383px-Flag_of_India.svg.png",
  },
  {
    comment: `Thank you ProLog team for organising this wonderful conference at a fantastic location as well. Colombo was a good venue.  
              This was the first time we attended the ProLog conference and we really enjoyed and had good meetings as well.  
              Met some old friends and made new ones too !  
              Happy to be part of the ProLog family. Looking forward to meeting you all next time.  
              GoPro !! `,
    author: "Alan Barboza",
    designation: "AGM-International Sales - Flomic Global Logistics",
    logo: "https://nucleusprolognet.s3.amazonaws.com/conference-upload/Flomic%20Global%20Logistics%20Ltd_Logo%20%28Final%29.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Dear Ganga and the Prolog Team, 
      It was a pleasure attending the conference and having the opportunity to meet and connect with new and Old friends. I am truly delighted to have been part of it and look forward to seeing and meeting all of you again soon. `,
    author: "Harpreet Singh",
    designation: "Operation Head - Spedition",
    logo: "https://nucleusprolognet.s3.amazonaws.com/harpreetsingh%40speditionindia.com/spedition%20logo.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Thanks to all the ProLog team for a very welcoming and enjoyable conference experience. And thanks to all the attendees too, we at Your Cargo Contact look forward to working with you in the near future. Go Pro! `,
    author: "Simon Corney",
    designation: "Operations Manager - Your Cargo Contact B.V.",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/Screenshot%202024-07-28%20152341.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/383px-Flag_of_the_United_Kingdom_%281-2%29.svg.png",
  },
  {
    comment:
      "Thanks to all Prolog team for this great organization. We look forward to seeing you all again.",
    author: "Ayesen Kesgin",
    designation:
      "Corporate Sales Manager - Genel Transport Forwarding & Trade Co. Ltd.",
    logo: "https://nucleusprolognet.s3.amazonaws.com//1361%20x%20242%20.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/330px-Flag_of_Turkey.svg.png",
  },
  {
    comment: `Dear Ganga and Ganga girls,  
      Thanks for the experience and the opportunity you provided for us at Colombo.  
      All was good, we enjoyed it a lot, our friendships became deeper and we created fantastic connections which we do hope we can develop them together.  
      Thanks for being so Pro at letting us networking like this  
      And thanks for letting us having a family like Prolog! 
      Hope to see you all again ❤️💫 `,
    author: "Fatemeh Yahya",
    designation: "Overseas Manager - MTB",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/MTB%20hi-%20logo.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/383px-Flag_of_Iran.svg.png",
  },
  {
    comment: `Dear Prolog Team, 
      It was a great event and well organized from Gate-In to Gate-Out. Really we did wounderful network and met many new friends. Awaiting for the next year event. Once again thanks to PROLog team and all the friends`,
    author: "Zakir Hussain",
    designation: "MD - Bluemax Cargo Company LLC",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/Bluemax%20Logo.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Flag_of_Saudi_Arabia.svg/383px-Flag_of_Saudi_Arabia.svg.png",
  },
  {
    comment: `Hi Ganga and team! 
      The conference was a great time to meet and great the fraternity people.  
      Team AIL genuinely appreciates the meticulous efforts and the show that was put together.  
      Cheers to the entire team.  
      I had a great time networking with the industry stalwarts.  
      Looking forward to networking and team synergies.`,
    author: "Nehil Gosalia",
    designation: "Founder/Director - Anchorage Inc Logistics",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/AIL%20LOGO_PNG-01%202.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Dear Ganga & Prolog Team, 
      It was a great event and well organized from Gate-In to Gate-Out. Really we did wounderful network and met many new friends. Awaiting for the next year event. Once again thanks to PROLog team and all the friends...👍 `,
    author: "Rajasubramanian",
    designation: "Assistant VP - Logi-Sys | Softlink Global",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/WhatsApp%20Image%202024-07-24%20at%2015.47.47.jpeg",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Tireless efforts n hardwork in making the conference n event a great success..GOPRO team you all rock stars. 
        Pleasure meeting all of you. Taking great memories n hoping all to keep in touch even if not business as friends.  
        Best regards `,
    author: "Mina Rana",
    designation: "MD - All Trade & Cargo Network Ltd",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/ATC%20Logo.jpeg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/383px-Flag_of_Canada_%28Pantone%29.svg.png",
  },
  {
    comment: `Dear Ganga & Entire Prolog Team, 
        Thank you for wonderful arrangements, it was well planned and organized. Big thanks to all the sponsors as well. 
        Had a chance to meet some known friends and made new friends as well. This is just the beginning of new business relations and long lasting friendships. 
        Once again thank you for all your efforts and well planned PROfessional  Network Event  
        Gooooo PRO`,
    author: "Sheetal Shetty",
    designation: "Group Managing Director - Capricorn Logistics Pvt. Ltd ",
    logo: require("../assets/images/capricorn.png"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "I think I am not the only one who felt your sincerity, friendship, potential to give to the network but everybody those were together with us during the event. It was a real pleasure meeting everyone in first PRO Networking Conference.",
    author: "William",
    designation: "GM - Sea Center Shipping Services LLC",
    logo: "https://nucleusprolognet.s3.amazonaws.com/ops1%40seacenterss.com/scss%20logo%20%28002%29.jpeg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/383px-Flag_of_the_United_Arab_Emirates.svg.png",
  },
  {
    comment: `Dear Ganga and team, I appreciate the efforts taken by each and every one to make this conference a big success... GO PRO !!!!`,
    author: "Denny Varghese",
    designation: "Director - Logistics Consolidators",
    logo: "https://nucleusprolognet.s3.amazonaws.com/dennyvarghese%40logisticsconsolidators.com/LOGO%20%28002%29.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Kenya.svg/383px-Flag_of_Kenya.svg.png",
  },
  {
    comment: `Dear Ganga and Team…it was a wonderful arrangements right from arrival to departure and no much words to express…u guys r the best forever and thank you for everything 🤗`,
    author: "Ramesh B",
    designation: "Director - Krishko",
    logo: "https://nucleusprolognet.s3.amazonaws.com/bramesh%40krishkologistics.com/Krishko%20Logo1%20%28002%29.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Dear  Prolog Team, 
      A very big thank you and many congratulations for the excellent arrangements and wonderful hosting .  
      Right from the arrival upto to departure everything was taken care without any lag or needing any follow up  ….  
      For me personally , the conference was a great one and was worth every minute . have met some great guys who have already become friends and I am sure the relationship will flourish both on business front and definitely on a personal level as well . 
      Wishing you all the very best of luck and have a safe flight back to base …..…. God speed !!  
      Hope to see to soon !!`,
    author: "Shankar Senthil",
    designation: "MD - Global Logistics",
    logo: "https://nucleusprolognet.s3.amazonaws.com/info%40groupe-global.net/logo.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Flag_of_the_Democratic_Republic_of_the_Congo.svg/340px-Flag_of_the_Democratic_Republic_of_the_Congo.svg.png",
  },
  {
    comment: `Hi Ganga and Team Prolog amazing conference and wonderfully organised….congrats to your team and looking forward for the next one 
Dear fellow members, amazing meeting you all wonderful souls and looking forward to connect with you guys soon 
Good luck to one and all 
Have a pleasant and safe flight 🙏🙏🙏🙏🙏`,
    author: "Sultan Basha",
    designation: "VP International - DahNAY Logistics Co., Ltd",
    logo: "https://nucleusprolognet.s3.amazonaws.com/sultan%40dahnaylogistics.com/DAhNAY%20Logo%20-%20Print-1.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/383px-Flag_of_Thailand.svg.png",
  },
  {
    comment:
      "PROLOG TEAM & Ganga congratulations, thank you so much the warm reception!",
    author: "Rafael Ayres",
    designation: "GM - Rental Logistica",
    logo: "https://nucleusprolognet.s3.amazonaws.com/contato%40rentalog.com.br/Rentalog-6.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/364px-Flag_of_Brazil.svg.png",
  },
  {
    comment: `Such a well run conference and was great seeing my old friends and meeting new ones. See you in between and at the next GooooooPro`,
    author: "Kevin Addison",
    designation: "DIrector - 1UP CARGO",
    logo: "https://nucleusprolognet.s3.amazonaws.com/kevin%401upcargo.com/1UP%20CARGO_final_07032018%20%28002%29%20logo%20%281%29.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/383px-Flag_of_Australia_%28converted%29.svg.png",
  },
  {
    comment: `Thanks to Ganga and his Prolog team. Great event, well organised. Keep up the spirit. 
              Wishing all a safe trip home and happy landing. `,
    author: "Stefan Heine",
    designation: "Network Director - Dirk Vollmer",
    logo: "https://nucleusprolognet.s3.amazonaws.com/d.vollmer%40spedition-dirk-vollmer.de/Logo3_0.jpg",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/383px-Flag_of_Germany.svg.png",
  },
  {
    comment: `The conference was surely a success with your leadership, dedication and tireless efforts of the whole team. One learns with experience and I feel in a very short time you have lead the network to one to be reckoned with.  `,
    author: "Nauman Akram",
    designation: "MD - RTW",
    logo: "https://nucleusprolognet.s3.amazonaws.com/akram%40rtw.ke/RTW%20logo%20-1.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Mozambique.svg/383px-Flag_of_Mozambique.svg.png",
  },
  {
    comment: `Hi Ganga, it was thoroughly a superb experience. I think the way you have nurtured the network, it is truly amazing. I am going back with a lot of memories and some amazing conversations with all fellow members. Hats off to you and your entire team! And eagerly looking forward to next year now. Cheers !!!`,
    author: "Kunal Maheshwari",
    designation: "Cheif Growth Officer - Logi-Sys | Softlink Global",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/WhatsApp%20Image%202024-07-24%20at%2015.47.47.jpeg",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `The event was absolutely Picture Perfect  
        Thoroughly enjoyed  
        Shall keep in touch  
        Taking back many sweet memories `,
    author: "Sudip Samaddar",
    designation: "CEO - I. W. Logistics Pvt. Ltd",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/IW_Logo-removebg.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Flag_of_Nepal.svg/209px-Flag_of_Nepal.svg.png",
  },
  {
    comment: `Thanks for having me and making me feel like part of the family. Its always such a pleasure and fun to be at the conference. Congrats on an amazing event and thanks for everything. The whole Prolog team is fantastic, and I really had a great time. See you at the next conference, hopefully. Once again thanks so much... `,
    author: "Sameer Ansari",
    designation: "CEO - Hexcore.ai",
    logo: "https://meetingapp-backend.s3.amazonaws.com/ganga%40prolognet.com/hexcore-logo.png",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/383px-Flag_of_Pakistan.svg.png",
  },
  {
    comment: `Thank you so much for everything you and your rockstar team did for us at Colombo. The planning, the people, and the place – all came together beautifully.
    I also appreciate your offer for IICS and we are working hard towards making that happen. Other than the cost, I have some more questions. Would you be available for a call next week? I am flexible with my schedule excepting Monday. Please let me know what works for you.
    We also look forward to an ongoing association with Prolog!`,
    author: "Bhavna",
    designation: "Co-Founder & CEO - Terminal2",
    logo: "https://nucleusprolognet.s3.amazonaws.com/richard%40cnhlogistics.com/Terminal2_trsp.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment: `Everything was perfect!!!!. We found everything to our satisfaction, indoors and outdoors. You took care of our individual needs and got everybody what they needed. We appreciate the Pro team for managing the itinerary on 3rd Pro conference. Thanks Mr. Ganga and Team.Thanks to each one whom we could connect personally and discus our co-business agendas.We could not meet some, but there is always a next time and Prolog gives us chance for the same. Awaiting 4th Pro conference. GO PRO!!!!!!!`,
    author: "Rajeevan",
    designation: "Director - Team GALEON SHIPPING",
    logo: "https://nucleusprolognet.s3.amazonaws.com/richard%40cnhlogistics.com/Team%20GALEON%20SHIPPING.png",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
];

export const aboutUs = [
  {
    id: 1,
    title: "About Us",
    subtitle: (
      <div style={{ lineHeight: 1.5 }}>
        <p>
          Professional Logistics Network (ProLog) stands out as a neutrally
          managed global network comprising independent freight forwarders and
          logistics providers. Beyond the standard benefits typical of logistics
          networks, such as financial protection, member-to-member instant
          payment, insurance products, and top-tier customer support, ProLog
          offers a range of unique features and benefits:
        </p>
      </div>
    ),
    image: require("../assets/images/touch.png"),
  },
  {
    id: 2,
    title: "What sets us apart",
    subtitle: (
      <div style={{ lineHeight: 1.5 }}>
        <p>
          What sets us apart is our innovative approach to networking, which has
          redefined networking. Here are the key features that set us apart:
        </p>
      </div>
    ),
    image: require("../assets/images/about/apart.png"),
  },

  {
    id: 3,
    title: "What do you get?",
    subtitle:
      "You can immediately communicate with fellow members through our portal itself - submit enquiries, receive inquiries, know what is floating around. No longer do you need to deal with window shoppers cluttering your inbox. You can now pick and choose from the series of serious inquiries that you can find on our portal.",
    image: require("../assets/images/about/you-get.png"),
  },
  {
    id: 4,
    title: "What's in it for us?",
    subtitle:
      "Nothing… your growth is our growth. Your success - ours.  It is all included when you go for your PRO badge*.",
    image: require("../assets/images/about/for-us.png"),
  },
  {
    id: 5,
    title: "What's next?",
    subtitle:
      "The highlight: very soon you can convert your RFQ into a firm quote and use our portal to send and receive payments too. But that's the future we are the future.",
    image: require("../assets/images/about/next.png"),
  },
  {
    id: 6,
    title: "The power is in the number",
    subtitle:
      "With a potential 14000 forwarders lined up to sign up - we are ensuring that only the best of this 14000 get to the Partner level. We want the best to partner with the best. But there is a limit to how many can earn a PRO badge from every city. Our stringent vetting process assures that only the true professional becomes eligible to earn the PRO badge. We make sure that you have multiple options to select from globally. ProLog believes in the collective power and dedicatedly serves its members towards growth and success.",
    image: require("../assets/images/about/power.png"),
  },
];

export const conference = [
  {
    id: 3,
    title: "Third Pro Networking Conference",
    img: require("../assets/images/first_confer2.jpg"),
  },
  {
    id: 2,
    title: "Second Pro Networking Conference",
    img: require("../assets/images/first_confer2.jpg"),
  },
  {
    id: 1,
    title: "First Pro Networking Conference",
    img: require("../assets/images/first_confer1.jpg"),
  },
];

export const secondConference = [
  { id: 1, name: "1-1 Meetings", preview: "" },
  { id: 2, name: "Group Photo", preview: "" },
  { id: 3, name: "Lunch", preview: "" },
  { id: 4, name: "Opening Ceremony", preview: "" },
  { id: 5, name: "Registeration", preview: "" },
  { id: 6, name: "Staff", preview: "" },
  { id: 7, name: "Troja", preview: "" },
  { id: 8, name: "U Fleku", preview: "" },
  { id: 9, name: "Welcome Cocktail", preview: "" },
];

export const proAdvant = [
  {
    id: 1,
    redirect: "semi-exclusive",
    title: "Semi Exclusive",
    description:
      "We understand the need to have choices. With a few options you are assured that you can align with the right partner with the same mindset and ethics like yourself. Being a Semi-Exclusive network ensures that you get to choose from amongst the best. With a transparent view of your partners’ certifications and their expiry, you know the expertise you are tying up with. Similarly anyone searching for a forwarder in your domain is assured that he is partnering with the best.",
    img: require("../assets/images/home/semi-exclusive.png"),
  },
  {
    id: 2,
    redirect: "financial",
    title: "Financial Protection",
    description:
      "Work in a safe and secure environment where your transactions between PRO members are protected. Your membership comes preloaded with Pro-shield – our top of the line financial protection program at no additional cost to you. On par with the best in the industry, when you bear this mark and we bear your risk.",
    img: require("../assets/images/home/financial.png"),
  },
  {
    id: 3,
    redirect: "mobile-app",
    title: "Mobile App",
    description:
      "In tune with the times, and allowing you to be more efficient than before, our Mobile App packs the most powerful features of our state of the art dashboard into your handheld. With the mobile app you can now perform smart search, view and respond to inquiries and also raise new inquiries. Experience the power of true networking with our app available on the Google Play Store and the Apple App Store.",
    img: require("../assets/images/home/mobile.png"),
  },
  {
    id: 4,
    redirect: "strategic-partners",
    title: "Access to top class strategic partners",
    description:
      "Everything you need to know about your partner is right where you want it to be – on one screen. As a Pro member you can view your partner’s profile, specialty, service, contacts, accreditation, certifications, branches and even reach out to them with just a click of your mouse. What better way to network than this.",
    img: require("../assets/images/home/strategic-partner.png"),
  },
  {
    id: 5,
    redirect: "social-media",
    title: "Complete social media exposure for maximum visibility",
    description:
      "Why do you join a network? To network, to be visible and that’s exactly what we help you achieve. Our social media is your podium. We are happy to announce all your achievements… right from your enrolment with us and then we repost this onto the several groups we are part of. We also help you with an email blast that announces your arrival with a bang across the tens of thousands of forwarders across the globe.",
    img: require("../assets/images/home/social-media.png"),
  },
  {
    id: 6,
    redirect: "networking",
    title: "Networking right from the first month of membership",
    description:
      "When you join Professional Logistics Network, you are joining a network that does what the name suggests Professionally helps you Network. You are invited to join our monthly mini networking webinar where you get to introduce your company, meet with fellow forwarders from across the globe 1-1 in our own version of networking. You start networking right from the word Go Unbelievable, but true. This has proved to be a great success and you have to be a part of it to experience it.",
    img: require("../assets/images/home/networking.png"),
  },
  {
    id: 7,
    redirect: "support-device",
    title: "Round the clock support service",
    description:
      "With support centers across timezones, we are able to provide you with the much required and timely support as you need it and when you need it. We are available round the clock, even on holidays. You can reach us directly onto our Whatsapp right from the website. No more waiting for interactive chats with a bot.. you will be connected a human who will respond to you in real time. Now that’s true support!!!",
    img: require("../assets/images/home/support.png"),
  },
  {
    id: 8,
    redirect: "member-to-member",
    title: "Member to member payment system",
    description:
      "PRO-Pay – our unparalleled premium member to member payment system with less or no transaction fees is a unique feature that saves you thousands of dollars annually just by saving on banking fees. Every penny saved is a penny earned in the true spirit. With multi factor authentications, Pro Pay ensures that your money is safe until you authorize it to be transacted.",
    img: require("../assets/images/home/member-to-member.png"),
  },
  {
    id: 9,
    redirect: "many-more",
    title: "and many more...",
    description:
      "Strategic Partnerships with Cargowise, Zybotech, InspeXion, Xchange containers, Shipnext, Ratesland Annual conferences in top class venues and 5 star properties Self serve portal permitting you to upload your documents and download your membership certificates and Personalised PRO Logo Constantly evolving features and benefits…",
    img: require("../assets/images/home/many-more.png"),
  },
];

export const AboutUsBulletPoints = [
  {
    id: 1,
    heading: "Completely Menuless State-of-the-Art Dashboard:",
    text: "ProLog boasts a cutting-edge dashboard design that eliminates traditional menu structures for a seamless and intuitive user experience.",
    img: require("../assets/images/about/dashboard.png"),
  },
  {
    id: 2,
    heading: "Smart Search and Smart RFQ:",
    text: " The network provides advanced search capabilities and request for quotation functionalities, enhancing efficiency and accuracy in sourcing logistics solutions.",
    img: require("../assets/images/about/fresco.png"),
  },
  {
    id: 3,
    heading: "Who Viewed My Profile:",
    text: "This feature facilitates business lead generation by allowing members to track who has viewed their profiles within the network.",
    img: require("../assets/images/about/cowboy.png"),
  },
  {
    id: 4,
    heading: "Mobile App:",
    text: "ProLog offers a mobile application, enabling members to access network features and services on the go, enhancing convenience and accessibility.",
    img: require("../assets/images/about/mobile.png"),
  },
  {
    id: 5,
    heading: "Semi-Exclusive Network:",
    text: "Membership in ProLog offers access to a semi-exclusive network, fostering deeper connections and collaborations among members.",
    img: require("../assets/images/about/network.png"),
  },
  {
    id: 6,
    heading: "Monthly Networking Webinars:",
    text: "Members benefit from monthly networking webinars, facilitating networking opportunities right from the first month of membership.",
    img: require("../assets/images/about/elearning.png"),
  },
  {
    id: 7,
    heading: "Loyalty Points and Rewards System:",
    text: "ProLog implements a loyalty program with points and rewards, incentivizing member engagement and participation.",
    img: require("../assets/images/about/reward.png"),
  },
  {
    id: 8,
    heading: "Complete Social Media Coverage:",
    text: "The network ensures comprehensive social media coverage, amplifying members' visibility and reach across digital platforms.",
    img: require("../assets/images/about/wifi.png"),
  },
];

export const SetUsApartBulletPoints = [
  {
    id: 1,
    heading: "Menuless Dashboard:",
    text: "Our dashboard eschews traditional menu structures, offering a seamless and intuitive user experience without the clutter of navigation menus.",
    img: require("../assets/images/about/dashboard.png"),
  },
  {
    id: 2,
    heading: "Visually Enticing Screens:",
    text: "We prioritize aesthetics, providing visually engaging screens that captivate users and enhance their interaction with the platform.",
    img: require("../assets/images/about/binary-code.png"),
  },
  {
    id: 3,
    heading: "Simple Interface:",
    text: "Our interface is designed for ease of use, making it straightforward for users to understand and navigate the platform without the need for extensive training or technical expertise.",
    img: require("../assets/images/about/banner.png"),
  },
  {
    id: 4,
    heading: "High-Level Security and Privacy:",
    text: "Security and privacy are paramount in our network, with robust measures in place to safeguard sensitive data and ensure user confidentiality.",
    img: require("../assets/images/about/lock.png"),
  },
  {
    id: 5,
    heading: "Hi-Tech Back End:",
    text: "Behind the scenes, our network boasts a state-of-the-art backend infrastructure, leveraging cutting-edge technology to deliver reliable performance and scalability.",
    img: require("../assets/images/about/dashboard.png"),
  },
];
